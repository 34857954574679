<template>
  <div v-show="show" :class="{ border: !notBorder }" class="zem-collapse">
    <div
      :class="{
        bold: !titleNotBold,
        'arrow-header': !arrow,
        [classHeader]: classHeader,
      }"
      class="zem-collapse__header"
    >
      <img
        v-if="arrow"
        :class="{ rotate: !isBodyVisible }"
        :src="require('@/assets/icons/dropdown-arrow-primary.svg')"
        alt=""
        class="zem-collapse__icon"
        @click="onHeaderClick"
      />
      <img
        v-else-if="!isBodyVisible"
        :class="{ rotate: !isBodyVisible }"
        :src="require('@/assets/icons/arrow2.svg')"
        alt=""
        class="zem-collapse__icon"
        @click="onHeaderClick"
      />
      <img
        v-else
        :class="{ rotate: !isBodyVisible }"
        :src="require('@/assets/icons/arrow2active.svg')"
        alt=""
        class="zem-collapse__icon"
        @click="onHeaderClick"
      />

      <div
        v-if="arrow && title"
        :class="{ 'flex-grow': !info, [classTitle]: classTitle }"
        class="zem-collapse__title"
        @click="onHeaderClick"
        v-html="title"
      ></div>
      <div
        v-else-if="title"
        :class="{ 'flex-grow': !info, 'pt-0 pb-0': !arrow }"
        class="zem-collapse__title"
        @click="onHeaderClick"
        v-html="title"
      ></div>
      <div
        v-else
        :class="{ 'flex-grow': !info, 'pt-0 pb-0': !arrow }"
        class="zem-collapse__title"
        @click="onHeaderClick"
      >
        <slot name="title"></slot>
      </div>

      <div v-if="info" :data-tooltip="info" class="zem-collapse__info">
        <img :src="require('@/assets/icons/info.svg')" alt="" />
      </div>

      <slot name="header" />

      <zem-badge v-if="badgeError" class="ml-2 mr-2" type="error">
        {{ badgeError }}
      </zem-badge>
    </div>
    <div
      v-if="isBodyVisible"
      class="zem-collapse__body"
      :class="{ 'mt-1': !arrow, [classBody]: classBody }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import ZemBadge from "@/components/ui/ZemBadge";

export default {
  components: { ZemBadge },
  props: {
    isOpenedDefault: {
      type: [Boolean],
      default: false,
    },
    info: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    badgeError: {
      type: String,
      default: "",
    },
    titleNotBold: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    notBorder: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    classHeader: {
      type: String,
      default: "",
    },
    classTitle: {
      type: String,
      default: "",
    },
    classBody: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isBodyVisible: false,
    };
  },

  computed: {
    getNewFields() {
      return this.currentFields.possibleToCreate;
    },
    read() {
      return this.$store.state.profile.read;
    },
  },

  watch: {
    currentFields() {
      this.$emit("update-data", this.currentFields);
    },
  },

  mounted() {
    this.isBodyVisible = this.isOpenedDefault;
  },

  methods: {
    onHeaderClick() {
      this.isBodyVisible = !this.isBodyVisible;
      this.$emit("collapse", this.isBodyVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
.zem-collapse {
  width: 100%;
  margin-top: 12px;
  border-radius: 4px;

  &.border {
    border: 1px solid #f0f0f0;
  }

  &__title {
    padding: 10px 0;

    &.flex-grow {
      flex-grow: 1;
    }
  }

  &__info {
    margin-left: 5px;
    position: relative;
    height: 100%;
    width: 18px;

    img {
      position: absolute;
      top: 11px;
      width: 18px;
      height: 18px;
    }
  }

  &__delete {
    margin-right: 10px;
  }

  &__copy {
    margin-right: 10px;
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    font-size: 22px;
    color: $color-accent;
  }

  &__input {
    display: flex;
    flex: 1;
  }

  &__button {
    padding: 0;
    margin: 0 0 0 5px;
    width: 30px;
    height: 17px;
    min-width: 30px;
  }

  &__icon {
    margin-right: 8px;
    transform: rotateZ(180deg);

    &.rotate {
      transform: rotateZ(0deg);
    }
  }

  &__header {
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: $color-mine-shaft;
    padding-left: 12px;
    user-select: none;

    &.arrow-header {
      height: 30px;
    }

    &:hover {
      //background: $color-mystic;
      cursor: pointer;
    }

    &.bold {
      font-weight: 700;
    }
  }

  &__body {
    display: block;
    padding: 0 12px 12px;

    .zem-dropdown-head {
      margin: 0;
    }
  }

  [data-tooltip] {
    position: relative; /* Относительное позиционирование */
  }

  [data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 200px; /* Ширина подсказки */
    left: 0;
    bottom: 1rem; /* Положение подсказки */
    background: #595959;
    border-radius: 2px;
    color: #fff; /* Цвет текста */
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    padding: 6px 8px; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 0.5s; /* Время появления подсказки */
  }

  [data-tooltip]:hover::after {
    opacity: 1; /* Показываем подсказку */
    bottom: 2rem; /* Положение подсказки */
  }
}
</style>
