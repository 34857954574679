<template>
  <div class="zem-input-container">
    <label v-if="title">{{ title }}</label>
    <input
      ref="input"
      :class="[
        inputClass,
        {
          'phone-mask': mask === 'phone',
          'integer-input': integer,
          'title-input': title,
          'tw-pr-10 tw-truncate': isDropdown,
          'tw-hidden': type === 'file',
          error: errorFormat,
        },
      ]"
      :disabled="disabled"
      :maxlength="maxLength"
      :min="min"
      :placeholder="placeholder"
      :required="required"
      :type="isShowPassword ? 'text' : type"
      :value="value"
      class="zem-input"
      @blur="onBlurEvent"
      @click="onClickEvent"
      @focus="onFocusEvent"
      @input="onUpdateEvent($event)"
      @paste="onPhonePaste"
      @keydown.enter="onClickEnterEvent($event.target.value)"
    />
    <div v-if="type === 'password'" @click="isShowPassword = !isShowPassword" class="eye-icon">
      <i-eye v-if="!isShowPassword" />
      <i-eye-slash v-else />
    </div>
    <button
      v-if="type === 'file'"
      :class="{'tw-text-[#9BA6B2]': !fileName}"
      class="upload-button"
      @click="triggerFileInput"
    >
      {{ fileName ? fileName : 'Выбрать файл' }}
    </button>
    <span v-if="errorFormat" class="zem-input__error">{{ errorFormat }}</span>
  </div>
</template>

<script>
import {formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import IEye from '@/components/icons/iEye.vue'
import IEyeSlash from '@/components/icons/iEyeSlash.vue'

export default {
  name: 'ZemInput',
  components: {IEyeSlash, IEye},
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 9999999,
    },
    min: {
      type: Number,
      default: 0,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    integer: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    errorTitle: {
      type: String,
      default: '',
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    isMinZero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: '',
      isShowPassword: false,
    }
  },
  computed: {
    inputClass() {
      if (this.isCompact) {
        return 'zem-input--compact'
      }
      return ''
    },
    errorFormat() {
      if (this.errors.length > 0) {
        const index = this.errors.findIndex(item => item.title === this.errorTitle)
        return index === -1 ? '' : this.errors[index].text
      } else {
        return ''
      }
    },
  },
  mounted() {
    if (this.mask === 'phone') {
      this.phoneInput()
    }
    if (this.integer) {
      this.integerInput()
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.input.click()
    },
    onClickEvent() {
      this.$emit('click')
    },
    onClickEnterEvent(value) {
      this.$emit('update-press-enter', value)
    },
    onUpdateEvent(event) {
      console.log(event.target.value)
      if (this.mask === 'phone') {
        this.$emit(
          'input',
          event.target.value[0] === '+'
            ? formattedPhone(phoneTrim(event.target.value.slice(1, 18)))
            : formattedPhone(phoneTrim(event.target.value.slice(0, 17)))
        )
      } else if (this.type === 'file') {
        const file = event.target.files[0]
        if (file) {
          this.fileName = file.name
          this.$emit('input', event)
        }
      } else if (this.isMinZero && event.target.value < 0) {
        this.$emit('input', event.target.value.slice(1))
      } else {
        this.$emit('input', event.target.value)
      }
    },
    onFocusEvent() {
      this.$emit('focus')
    },
    onBlurEvent() {
      this.$emit('blur')
    },
    phoneInput() {
      let phoneInputs = document.getElementsByClassName('phone-mask')

      for (let phoneInput of phoneInputs) {
        phoneInput.addEventListener('keydown', this.onPhoneKeyDown)
        phoneInput.addEventListener('input', this.onPhoneInput, false)
      }
    },
    getInputNumbersValue(input) {
      // Return stripped input value — just numbers
      return input.value.replace(/\D/g, '')
    },
    onPhonePaste(e) {
      if (this.mask === 'phone') {
        let input = e.target,
          inputNumbersValue = this.getInputNumbersValue(input)
        let pasted = e.clipboardData || window.clipboardData
        if (pasted) {
          let pastedText = pasted.getData('Text')
          if (/\D/g.test(pastedText)) {
            input.value = inputNumbersValue
            return null
          }
          this.$emit('update-phone', formattedPhone(phoneTrim(pastedText)))
        }
      }
    },
    onPhoneInput(e) {
      if (this.mask === 'phone') {
        let input = e.target,
          inputNumbersValue = this.getInputNumbersValue(input),
          selectionStart = input.selectionStart,
          formattedInputValue = ''

        if (!inputNumbersValue) {
          return (input.value = '')
        }

        if (input.value.length !== selectionStart) {
          // Editing in the middle of input, not last symbol
          if (e.data && /\D/g.test(e.data)) {
            // Attempt to input non-numeric symbol
            input.value = inputNumbersValue
          }
          return
        }

        if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
          if (inputNumbersValue[0] === '9') inputNumbersValue = '7' + inputNumbersValue
          let firstSymbols = inputNumbersValue[0] === '8' ? '8' : '+7'
          formattedInputValue = input.value = firstSymbols + ' '
          if (inputNumbersValue.length > 1) {
            formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
          }
          if (inputNumbersValue.length >= 5) {
            formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
          }
          if (inputNumbersValue.length >= 8) {
            formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
          }
          if (inputNumbersValue.length >= 10) {
            formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
          }
        } else {
          formattedInputValue = '+' + inputNumbersValue.substring(0, 16)
        }
        input.value = formattedInputValue
      }
    },
    onPhoneKeyDown(e) {
      if (this.mask === 'phone') {
        // Clear input after remove last symbol
        let inputValue = e.target.value.replace(/\D/g, '')
        if (e.keyCode === 8 && inputValue.length === 1) {
          e.target.value = ''
        }
      }
    },
    integerInput() {
      let integerInputs = document.getElementsByClassName('integer-input')

      for (let phoneInput of integerInputs) {
        phoneInput.addEventListener('input', this.onNumberInput)
      }
    },
    onNumberInput(e) {
      let input = e.target,
        value = input.value

      if (value.search(`^(\\d+)$`) !== 0) {
        input.value = value.substring(0, value.length - 1)
        this.onUpdateEvent(value.substring(0, value.length - 1))
      }

      let a = [
        {
          id: '1',
          partner_status_id: 'aYOxlpzRMwrX3gD7',
          final_budget: 1200,
        },
        {
          id: '2',
          partner_status_id: '39n0Z12OZGKERJgW',
          final_budget: 320,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-input {
  padding: 5px 8px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  color: $color-mine-shaft;
  border: 1px solid $color-catskill-grey;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  min-height: 26px;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
  }

  &:focus {
    border: 1px solid $color-accent;
  }

  &--compact {
    margin: 0;
    height: 25px;
  }

  &.error {
    border: 1px solid $color-red;
  }

  &__error {
    color: $color-red;
    font-size: 10px;
    margin-left: 10px;
  }

  &-container {
    width: 100%;
    position: relative;

    &.mt-0 {
      .zem-input {
        margin-top: 0;
      }
    }
  }

  &.title-input {
    margin: 0;
  }
}

.eye-icon {
  position: absolute;
  width: 12px;
  right: 10px;
  top: 8px;
  cursor: pointer;
  fill: #86909b;
}

label {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: $color-mine-shaft;
  text-align: left;
  margin-bottom: 3px;
}

.upload-button {
  text-align: start;
  width: 100%;
  padding: 5px 8px;
  border: 1px solid $color-catskill-grey;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  min-height: 26px;
  background-color: $color-white;
  cursor: pointer;
}
</style>
